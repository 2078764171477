import styled from "styled-components"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"
import { Container } from "@mui/material"

export const Section = styled(SectionWrapper)`
  background: linear-gradient(
    180deg,
    ${({ theme }) => theme.palette.secondary.light} 50%,
    ${({ theme }) => theme.palette.tertiary.main} 50%
  );
  padding: 2rem 0;
  ${({ theme }) => theme.breakpoints.down("md")} {
    padding-bottom: 0;
  }
`
export const Wrapper = styled(Container)``
export const Subtitle = styled.h3`
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.typography.pxToRem(32)};
  line-height: ${({ theme }) => theme.typography.pxToRem(36)};
  letter-spacing: ${({ theme }) => theme.typography.pxToRem(1)};
  color: ${({ theme }) => theme.palette.secondary.main};
  text-align: center;
  font-weight: bold;
  margin-bottom: 0.5rem;
  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: 1.5rem;
    line-height: normal;
  }
`
export const Title = styled.h2`
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: ${({ theme }) => theme.typography.pxToRem(32)};
  line-height: ${({ theme }) => theme.typography.pxToRem(36)};
  font-weight: 500;
  color: ${({ theme }) => theme.palette.primary.main};
  text-align: center;
  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: 1.5rem;
    line-height: normal;
  }
`
export const Description = styled.p`
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: ${({ theme }) => theme.typography.pxToRem(20)};
  line-height: ${({ theme }) => theme.typography.pxToRem(32)};
  color: ${({ theme }) => theme.palette.primary.main};
  text-align: center;
`
