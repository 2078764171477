import styled from "styled-components"
import FormatQuoteIcon from "@mui/icons-material/FormatQuote"
import { Typography } from "@mui/material"
import CustomImage from "../custom-image/custom-image.component"
import QuotesIcon from "../../assets/quotes.svg"

export const Wrapper = styled.div`
  position: relative;
  min-height: 400px;
  ${({ theme }) => theme.breakpoints.down("md")} {
    min-height: 200px;
  }
`

export const QuoteIcon = styled(QuotesIcon)`
  fill: #d8d8d8;
  width: 90px;
  height: 77px;
  z-index: 1;
  position: absolute;
  top: -40px;
  left: 0;
  svg {
    object-fit: cover !important;
  }
  ${({ theme }) => theme.breakpoints.down("md")} {
    width: 90px;
    height: auto;
    top: -51px;
    left: 26px;
  }
`

export const DescriptionWrapper = styled.div`
  margin: 3em 0 2em;
  border-radius: 8px;
  background-color: #eef7ff;
  display: flex;
  align-items: center;
  padding-bottom: 2rem;
  ${({ theme }) => theme.breakpoints.up("md")} {
    min-height: 400px;
  }
`

export const Description = styled(Typography)`
  color: #09090b;
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: ${({ theme }) => theme.typography.pxToRem(32)};
  font-weight: 300;
  letter-spacing: 0;
  line-height: 38px;
  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: 1.2rem;
    line-height: normal;
  }
`

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const InfoText = styled.span`
  color: #566171;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 24px;
  font-weight: 300;
`

export const BottomWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1em;
  margin-top: 2.5rem;
`

export const Image = styled(CustomImage)`
  width: 60px;
  height: 60px;
  max-width: 100%;
  img {
    border-radius: 50%;
  }
`

export const TextWrapper = styled.div`
  max-width: 90%;
  margin: auto;
  ${({ theme }) => theme.breakpoints.down("md")} {
    margin-top: 3rem;
  }
`
