import styled from "styled-components"
import CustomLink from "../custom-link/custom-link.component"

export const Card = styled.div`
  width: 100%;
  height: 100%;
`
export const Link = styled(CustomLink)`
  width: 100%;
  height: 100%;
`
export const CardContainer = styled.div`
  border-radius: 20px;
  background: linear-gradient(154.46deg, #15151a 0%, #09090b 100%);
  padding: 3.5rem;
  width: 100%;
  height: 100%;
`
export const Title = styled.h3`
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: ${({ theme }) => theme.typography.pxToRem(32)};
  line-height: ${({ theme }) => theme.typography.pxToRem(36)};
  color: ${({ theme }) => theme.palette.tertiary.main};
  font-weight: 500;
`
export const Description = styled.p`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.typography.pxToRem(16)};
  line-height: ${({ theme }) => theme.typography.pxToRem(24)};
  color: ${({ theme }) => theme.palette.tertiary.main};
`
