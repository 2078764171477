import React from "react"

import * as S from "./highlights.styles"
import { Grid } from "@mui/material"
import CardHighlights from "../../components/card-highlights/card-highlights.component"

const Highlights = ({ items, subtitle, title, description }) => {
  if (!items) return null
  return (
    <S.Section>
      <S.Wrapper>
        <S.Subtitle>{subtitle}</S.Subtitle>
        <S.Title>{title}</S.Title>
        <S.Description>{description}</S.Description>
        <Grid
          container
          justifyContent="center"
          spacing={5}
          style={{ marginBottom: "2rem" }}
        >
          {items?.map((item, index) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={items?.length > 3 ? 3 : 4}
              key={index - item.title}
            >
              <CardHighlights {...item} />
            </Grid>
          ))}
        </Grid>
      </S.Wrapper>
    </S.Section>
  )
}

export default Highlights
