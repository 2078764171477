import React from "react"
import * as S from "./testimonial-card.styles"
import { Container } from "@mui/material"

const TestimonialCard = ({ description, author, role, company, image }) => {
  return (
    <S.Wrapper>
      <S.QuoteIcon />
      <Container>
        <S.DescriptionWrapper>
          <S.TextWrapper>
            <S.Description>{description}</S.Description>
            <S.BottomWrapper>
              {image && <S.Image img={image} />}
              <S.InfoWrapper>
                <S.InfoText style={{ fontWeight: "bold" }}>{author}</S.InfoText>
                {role && <S.InfoText>{role}</S.InfoText>}
                {company && <S.InfoText>{company}</S.InfoText>}
              </S.InfoWrapper>
            </S.BottomWrapper>
          </S.TextWrapper>
        </S.DescriptionWrapper>
      </Container>
    </S.Wrapper>
  )
}

export default TestimonialCard
