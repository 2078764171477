import React from "react"

import * as S from "./card-highlights.styles"

const CardHighlights = ({ url, title, description }) => {
  const call =
    title === "Support"
      ? "tel:833-732-3977"
      : title === "Sales"
      ? "tel:833-732-3987"
      : title === "Main" && "tel:833-732-3967"
  return (
    <S.Card>
      {call ? (
        <S.Link href={call}>
          <S.CardContainer>
            <S.Title>{title}</S.Title>
            <S.Description>{description}</S.Description>
          </S.CardContainer>
        </S.Link>
      ) : (
        <S.CardContainer>
          <S.Title>{title}</S.Title>
          <S.Description>{description}</S.Description>
        </S.CardContainer>
      )}
    </S.Card>
  )
}

export default CardHighlights
